import React, { useEffect, useState } from 'react';
import baystakeAudio from '../baystake.mp3'; // Relative path since both are in the same directory

const BaystakeListener = () => {
    const [typedKeys, setTypedKeys] = useState('');

    useEffect(() => {
        const handleKeypress = (event) => {
            setTypedKeys((prev) => (prev + event.key).slice(-8)); // Track last 8 characters

            // Check if the typed sequence is "baystake"
            if (typedKeys === 'baystake') {
                playAudio();
            }
        };

        const playAudio = () => {
            const audio = new Audio(baystakeAudio);
            audio.play().catch(error => {
                console.error("Audio play error:", error);
            });
        };

        window.addEventListener('keypress', handleKeypress);

        return () => {
            window.removeEventListener('keypress', handleKeypress);
        };
    }, [typedKeys]);

    return null;
};

export default BaystakeListener;
